import React, { useState } from 'react';

import { Form as FinalForm, Field } from 'react-final-form';

import createStyles from '@guestyci/foundation/createStyles';
import Form from '@guestyci/foundation/Form';
import FormField from '@guestyci/foundation/FormField';
import FormGroup from '@guestyci/foundation/FormGroup';
import { FormProvider } from '@guestyci/foundation/enums';
import Input from '@guestyci/foundation/Input';
import Col from '@guestyci/foundation/Layout/Col';
import { email } from '@guestyci/foundation/validators';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import { utils } from 'utils';

import Subtitle from '../components/Subtitle';
import Title from '../components/Title';

export const useStyles = createStyles(
  theme => ({
    form: {
      width: '100%',
      marginBottom: 15,
    },
    submit: {
      margin: '5px auto 0',
      width: '100%',
      height: 46,
      backgroundColor: theme.palette.background.default,
      boxShadow: '0px 2px 4px rgba(33, 33, 33, 0.1)',
      borderRadius: 8,
      fontSize: '1rem',

      '&:focus': {
        backgroundColor: '#2271ff',
        border: `1px solid ${theme.palette.border}`,
        boxShadow: '0 0 8px #51cbee',
        maskImage: 'none',
      },

      '& > span': {
        fontSize: '1rem !important',
        color: '#ffffff',
      },
    },
    col: {
      marginBottom: 20,
    },
    formField: {
      border: `1px solid ${theme.palette.border}`,
      borderRadius: 4,
      color: '#5e5e5e !important',
      '&:focus': {
        boxShadow: '0 0 8px #51cbee',
        border: `solid 1px ${theme.palette.primary.default}`,
      },
    },
  }),
  { name: 'ForgotForm' }
);

const ForgotForm = ({ onResetSent }) => {
  const classes = useStyles();
  const [error, setError] = useState();
  const title = t('Forgot password?');
  const subtitle = t('We’ll email you a link you can use to reset your password securely.');
  const genericError = t('Incorrect email, please try again');
  const emailLabel = t('Email');
  const submit = t('Reset password');
  const cancel = t('Cancel');

  const handleFormSubmit = async values => {
    try {
      utils.eventsLogger.unifiedLoginDio.track('reset_password', 'click', {
        source: 'reset_page',
      });
      await utils.api.resetPassword(values.email);
      onResetSent();
    } catch (e) {
      setError(genericError);
    }
  };

  return (
    <>
      <Col align="center" justify="center" spacing={1} className={classes.col}>
        <Title>{title}</Title>
        <Subtitle color={error ? 'error' : '#77838D'}>
          {error || subtitle}
        </Subtitle>
      </Col>
      <FinalForm
        onSubmit={handleFormSubmit}
        initialValues={{}}
        render={({ handleSubmit, submitting }) => (
          <Form
            className={classes.form}
            onSubmit={handleSubmit}
            provider={FormProvider.Final}
            fieldInstance={Field}
          >
            <FormGroup>
              <FormField
                label={emailLabel}
                name="email"
                required
                validate={[email]}
              >
                <Input
                  type="email"
                  placeholder="name@company.com"
                  className={classes.formField}
                />
              </FormField>
              <RaisedButton
                className={classes.submit}
                type="submit"
                disabled={submitting}
                size="lg"
              >
                {submit}
              </RaisedButton>
            </FormGroup>
          </Form>
        )}
      />
      <TextField>
        <a className={classes.link} href="./login">
          {cancel}
        </a>
      </TextField>
    </>
  );
};
export default ForgotForm;
