import t from '@guestyci/localize/t.macro';

const getOktaTranslations = () => {
  const oktaLoginTranslations = {
    'customButton.title': t('Continue'),
    'primaryauth.title': t('Welcome!'),
    'error.username.required': t('Please enter an email'),
    'primaryauth.username.placeholder': t('Email'),
    'primaryauth.username.tooltip': t('Email'),
    'registration.signup.label': t('New to Guesty?'),
    'registration.signup.text': t('Register a new account'),
    'password.forgot.emailSent.title': t('Check your email'),
    'password.forgot.sendEmail': t('Reset password'),
    'password.forgot.email.or.username.placeholder': t(
      'We’ll email you a link you can use to reset your password securely.'
    ),
    'password.forgot.email.or.username.tooltip': t(' Email'),
    help: t('Need help?'),
    'enroll.choices.title': t('Set up Multi-factor Authentication'),
    'error.auth.lockedOut': t(
      'Too many failed attempts. Please try again in 10 minutes.'
    ),
    'errors.E0000004': t('Sorry, we do not recognize those details.'),
    // MFA translations
    'mfa.backToFactors': t('Back to authentication setup'),
    'enroll.choices.description.specific': t(
      '{val} requires an additional identification method to add a layer of security when signing in. Select an authentication option:',
      { val: '{0}' }
    ),
    'enroll.sms.setup': t('Set up SMS Authentication'),
    'email.enroll.title': t('Email Authentication'),
    'email.button.send': t('Send code'),
    'email.mfa.email.sent.description': t(
      'We sent a verification code to {val}. Enter the code below to verify your email.',
      { val: '{0}' }
    ),
    'email.button.resend': t('Resend code'),
    'email.code.not.received': "Didn't receive a code via email?",
    'email.code.label': t('Enter code'),
    'factor.sms.description': t('Get an SMS with a verification code.'),
    'factor.email.description': t('Get an email with a verification code.'),
    'factor.totpSoft.description': t(
      'Use the Google Authenticator app to get a verification code.'
    ),
    'factor.sms.time.warning': "Didn't receive a code via SMS? Resend code",
    'password.reset.title.specific': t('Create your {val} password', {
      val: '{0}',
    }),
    'password.reset': t('Create password'),
    // {0} is a generated list of requirements, based on translations from the below keys.
    'password.complexity.requirements': t('Password must be {val}.', {
      val: '{0}',
    }),
  };

  return oktaLoginTranslations;
};
export default getOktaTranslations;
