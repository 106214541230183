import cn from 'classnames';
import Col from '@guestyci/foundation/Layout/Col';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';

import { utils } from 'utils';

const useStyles = createStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacer(2),
    flexWrap: 'wrap',
    marginTop: theme.spacer(2),

    '& > li': {
      width: 195,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.placeholder,

      '&::before': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        marginRight: theme.spacer(1),
        backgroundColor: theme.palette.background.secondary,
      },

      '&.active': {
        color: theme.palette.text.default,

        '&::before': {
          backgroundColor: theme.palette.success.default,
        },
      },
    },
  },
  errorMessage: {
    '#okta-sign-in &': {
      color: theme.palette.error.text,
      fontSize: 12,
      marginTop: theme.spacer(2),
      marginBottom: theme.spacer(2),
      fontFamily: 'var(--font-family-sans-serif)',
    },
  },
}));

const PasswordValidation = ({ password, showWarning }) => {
  const classes = useStyles();
  const i18nPasswordContainsError = t(
    "Your password doesn't meet the minimum security requirements."
  );
  const length = t('At least 8 characters');
  const number = t('At least one number');
  const lowercase = t('At least one lowercase character');
  const uppercase = t('At least one uppercase character');
  const i18nErrors = [length, number, lowercase, uppercase];
  const rules = utils.validators.passRegex.map((rule, index) => ({
    ...rule,
    i18n: i18nErrors[index],
  }));

  return (
    <Col spacing={1}>
      {showWarning && (
        <TextField
          className={cn(classes.errorMessage, 'guesty-invalid-password')}
        >
          {i18nPasswordContainsError}
        </TextField>
      )}
      <ul className={classes.root}>
        {rules.map(({ id, regex, i18n }) => (
          <li key={id} className={password.match(regex) ? 'active' : ''}>
            {i18n}
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default PasswordValidation;
