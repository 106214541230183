import okta from './okta';

import * as api from './api';
import * as localStorage from './localStorage';
import * as validators from './validators';
import * as eventsLogger from './eventsLogger';

import reportFullstory from './reportFullstory';
import useDocumentTitle from './useDocumentTitle';
import usePageView from './usePageView';
import saveUserData from './user';

import { getZendeskRedirectUrl } from './getRedirectUrl';
import { getEnv } from './env';
import { isHQDomain } from './getHQDomain';

const utils = {
  api,
  getZendeskRedirectUrl,
  getEnv,
  eventsLogger,
  localStorage,
  reportFullstory,
  useDocumentTitle,
  usePageView,
  saveUserData,
  validators,
  isHQDomain,
};

export { utils, okta };
