import React, { useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import { okta } from 'utils';
import { isAllowedOrigin, postMessageToParent, getWidgetConfig } from './utils';
import { OktaAuth } from '@okta/okta-auth-js';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';

const useStyles = createStyles(
  theme => ({
    '@global': {
      '#okta-sign-in.auth-container.main-container': {
        margin: 0,
        padding: 0,
        minHeight: 0,
      },
      '#okta-sign-in.auth-container .auth-content': {
        padding: 0,
        margin: 0,
      },
      '#okta-sign-in .auth-header': {
        display: 'none',
      },
      '#okta-sign-in .primary-auth': {
        padding: 0,
      },
    },
  }),
  { name: 'GoogleConnect' }
);

const adjustWidgetStyling = queryParams => {
  const container = document.querySelector('#okta-sign-in');
  if (container) {
    ['okta-form-title', 'auth-divider', 'auth-footer'].forEach(className => {
      const element = container.querySelector(`.${className}`);
      if (element) {
        element.remove();
      }
    });

    container.querySelector('form')?.remove();

    const widthParam = queryParams.get('btn_w');
    const heightParam = queryParams.get('btn_h');
    const width = widthParam ? `${widthParam}px` : 'auto';
    const height = heightParam ? `${heightParam}px` : 'auto';

    Object.assign(container.style, {
      width,
      height,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0',
      background: 'transparent',
    });

    const buttonContainer = container.querySelector('.primary-auth-container');
    if (buttonContainer) {
      Object.assign(buttonContainer.style, {
        padding: '0',
        background: 'transparent',
      });
    }

    const button = container.querySelector('.social-auth-google-button');
    if (button) {
      Object.assign(button.style, {
        margin: '0',
        borderRadius: '50px',
        overflow: 'hidden',
      });

      const buttonChildren = button.querySelectorAll('*');
      buttonChildren.forEach(child => {
        child.style.borderRadius = '50px';
      });
    }

    const linkElement = container.querySelector('.social-auth-google-button');
    if (linkElement) {
      linkElement.innerHTML = t('Sign up with Google');
    }

    const outerContainer = document.getElementById('okta-signin-container');
    if (outerContainer) {
      outerContainer.style.background = 'transparent';
    }
  }
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const GoogleConnect = () => {
  const oidc = { ...okta.marketingOidc };
  const oktaAuth = new OktaAuth(oidc);
  const widgetRef = useRef();
  const queryParams = useQuery();

  useStyles();

  const setGoogleLoginWidgetState = async widget => {
    try {
      const tokens = await widget.showSignInToGetTokens({
        el: widgetRef.current,
      });

      await handleSignInSuccess(tokens);
    } catch (err) {
      postMessageToParent({
        type: 'GOOGLE_LOGIN_ERROR',
        error: err.message || 'Failed to login with Google',
      });
    }
  };

  const handleSignInSuccess = async tokens => {
    const email = tokens?.idToken?.claims?.email;
    oktaAuth.tokenManager.setTokens(tokens);

    try {
      const isExistingUser = tokens?.accessToken?.claims?.userId;
      const user = await oktaAuth.getUser();
      const {
        given_name: firstName,
        family_name: lastName,
        sub: oktaId,
      } = user;

      if (isExistingUser) {
        postMessageToParent({
          type: 'GOOGLE_LOGIN_SUCCESS_EXISTING_USER',
          email,
          firstName,
          lastName,
        });

        return;
      }

      oktaAuth.tokenManager.clear();

      postMessageToParent({
        type: 'GOOGLE_LOGIN_SUCCESS',
        oktaId,
        email,
        firstName,
        lastName,
      });
    } catch (error) {
      postMessageToParent({
        type: 'GOOGLE_LOGIN_ERROR',
        error: error.message || 'Failed to login with Google',
      });

      console.error('Error getting user:', error);
    }
  };

  useEffect(() => {
    if (!isAllowedOrigin()) {
      return;
    }

    const widget = new OktaSignIn(getWidgetConfig(oidc));
    widget.on('afterRender', context => {
      if (context.controller === 'primary-auth') {
        adjustWidgetStyling(queryParams);
      }
    });

    if (!widgetRef.current) {
      return;
    }

    setGoogleLoginWidgetState(widget);

    return () => widget.remove();
  }, [oktaAuth, oidc]);

  if (!isAllowedOrigin()) {
    console.error('Unauthorized domain');
    return null;
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={() => console.error('onAuthRequired')}
      restoreOriginalUri={() => console.error('restoreOriginalUri')}
    >
      <div>
        <div
          id="okta-signin-container"
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <div ref={widgetRef} />
        </div>
      </div>
    </Security>
  );
};

export default GoogleConnect;
